import Image from "next/image";
import { Content } from "@prismicio/client";
import { PrismicRichText, SliceComponentProps } from "@prismicio/react";
import { TitlePage } from "@components/commun";
import Picto from "@static/images/feature/circle-txt-mobile.png";
import style from "./RecettesSaines.module.scss";

export type RecettesSainesProps =
  SliceComponentProps<Content.RecettesSainesSlice>;

const RecettesSaines = ({ slice }: RecettesSainesProps): JSX.Element => {
  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className={style.root}
    >
      <div className="lk-wrap">
        <div className="lk-wrap-content">
          <div className={style.title}>
            <TitlePage>
              <PrismicRichText field={slice.primary.title} />
            </TitlePage>
          </div>
          <div className={style.description}>
            <PrismicRichText field={slice.primary.description} />
            <Image src={Picto} alt="" fill />
          </div>
        </div>
      </div>
    </section>
  );
};

export default RecettesSaines;
